.project-card {
    width: 100%;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}



.project-card-header {
    height: 400px;
    overflow: hidden;
}

.project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
}

.project-card:hover .project-image {
    transform: scale(1.1);
}

.project-card:hover {
    transform: translateY(-10px);
    cursor: pointer;
}

.project-card-body {
    background-color: #f8f8f8;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;
}

.project-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.project-card-title h4 {
    margin: 0;
    font-size: 1.4rem;
}

.tech-icons {
    display: flex;
    gap: 10px;
}

.tech-icon {
    font-size: 1.5rem;
    color: #555;
}

.project-card-description {
    text-align: left;
    color: #414141;
    font-size: 1.2rem;
    line-height: 1.5;
    min-height: 100px;
    max-height: 100px;
}