.ci-laravel::before {
  display: block;
  content: url('laravel.svg');
}

.ci-react::before {
  display: block;
  content: url('react.svg');
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #f0f0f0!important;
}

footer {
  margin-top: auto;
}

.header {
  background-color: white;
}

.divider-header h2 {
  font-size: 2.8rem;
  font-weight: 600;
}

.mt-6 {
  margin-top: 6rem!important;
}

.body-container {
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
}

.contact-header h2 {
  font-size: 3.8rem;
  font-weight: 600;
  color: #484B6A;
  font-weight: 700;
}

strong {
  color: #484B6A;
}

.contact-header h3 {
  font-size: 1.8rem;
}

.btn-custom {
  background-color: #484B6A;
  color: white;
  border: none;
  font-weight: 700;
  font-size: 1.2rem;
  padding: 0.7rem
}

textarea {
  resize: none;
}

.project-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
  min-height: 500px;
}

.project-text h2 {
  font-size: 1.1rem;
}

.project-text h1 {
  font-size: 2.2rem;
}

.project-details {
  display: flex;
  align-items: center;
}

.no-image {
  background-color: #d9d9d9;
  height: 500px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: lighter;
}