#headshot {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin-top: -20%;
    z-index: 1;
}

#headshot img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.btn-social:hover {
    transform: scale(1.03);
    border: 2px solid #484B6A;
    color: white;
}


.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
    width: 100%!important;
}

.hero-row {
    position:relative;
    flex-direction: column-reverse;
}

.hero-section {
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    #headshot {
        width: 450px;
        height: 450px;
        margin-top: 0;
    }
    
    .hero-row {
        flex-direction: row;
    }    

    .hero {
        height: 75vh;
    }

    .hero-section {
        padding-bottom: 10%;
    }
}
