.custom-shape-divider-bottom-1721735277 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1721735278 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}
  
.custom-shape-divider-bottom-1721735277 svg {
    position: relative;
    display: block;
    width: calc(183% + 1.3px);
    height: 163px;
}

.custom-shape-divider-bottom-1721735278 svg {
    position: relative;
    display: block;
    width: calc(183% + 1.3px);
    height: 263px;
}

.custom-shape-divider-bottom-1721735277 .shape-fill {
    fill: #F2F2F2;
}
  
.custom-shape-divider-bottom-1721735278 .shape-fill {
    fill: #F2F2F2;
}

@media screen and (min-width: 768px) {
    .custom-shape-divider-bottom-1721735277 svg {
        height: 363px;
    }
    
    .custom-shape-divider-bottom-1721735278 svg {
        height: 463px;
    }
}