#about h1 {
    font-size: 2.7rem;
    margin: 20px;
}
  
#about p {
    font-size: 1.6rem;
    margin: 20px;
}

#about i, span {
    font-size: 1.8rem;
}

#about {
    z-index: 1;
}

#socials {
    display: flex;
    flex-direction: column;
}


@media screen and (min-width: 768px) {
    #about h1 {
        font-size: 4rem;
    }
      
    #about p {
        font-size: 1.8rem;
    }
    
    #about i, span {
        font-size: 1.8rem;
    }    

    #socials {
        display: flex;
        flex-direction: row;
    }
}