.nav-link {
    font-size: 1.3rem!important;
    color: black!important;
}
.navbar-closer {
    display: none;
}

@media screen and (max-width: 768px) {
    .navbar-nav {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: 4;
        background-color: white;
        display: flex;
        justify-content: center;
    }
    
    .nav-link {
        font-size: 1.7rem!important;
        color: black!important;
    }
    
    .navbar-closer {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background-color: white;
        padding: 10px;
        border: none;
    }
    
    .navbar-toggler {
        border:none;
    }
    
}